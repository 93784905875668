import React, { useState } from "react"
import Layout from "../components/layout"
import SEO from "./../components/seo"

import { Link, navigate } from "gatsby"

import { useSelector, useDispatch } from "react-redux"
import { auth } from "./../actions/auth"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSignInAlt } from "@fortawesome/free-solid-svg-icons"

const ConnexionPage = () => {
  const identifiants = useSelector(s => s.identifiants)
  const loading = useSelector(s => s.loadingR)
  const dispatch = useDispatch()
  const [form, setForm] = useState({
    username: ``,
    password: ``,
  })

  const handleUpdate = event => {
    setForm({ ...form, [event.target.name]: event.target.value })
  }

  const handleSubmit = event => {
    event.preventDefault()

    dispatch(auth(form.username, form.password))
  }

  if (identifiants.identifiants !== null) {
    if (typeof window !== "undefined") {
      const redirect = new URLSearchParams(window.location.search).get("redirect")
      if (redirect === null) {
        navigate(`/`)
      } else {
        navigate(redirect)
      }
    }
  }

  return (
    <>
      <Layout banner={false}>
        <SEO 
          title="Connexion à votre espace personnel"
          description="Accédez à vos commandes de cadres, informations personnelles"
          canonical={process.env.GATSBY_URL_ACTUEL + '/connexion/'}
        />
        <h1>Connexion</h1>
        <div className="columns is-centered login">
          <div className="section column is-half couleurShadow">
            <div className="h3 dotted">
              <svg>
                <use xlinkHref="#connexion" />
              </svg>
              Connexion
            </div>
            <form method="post" onSubmit={event => handleSubmit(event)}>
              <div className="field">
                <label className="label" htmlFor="email">
                  Adresse email&nbsp;:
                </label>
                <div className="control">
                  <input type="text" id="email" name="username" onChange={handleUpdate} required className="input" placeholder="Votre adresse email" />
                </div>
              </div>
              <div className="field">
                <label className="label" htmlFor="password">
                  Mot de passe&nbsp;:
                </label>
                <div className="control">
                  <input type="password" id="password" name="password" onChange={handleUpdate} required className="input" placeholder="Votre mot de passe" />
                </div>
              </div>
              {loading.status === false ? (
                <div className="notification is-danger">L&apos;adresse e-mail ou le mot de passe que vous avez entré n&apos;est pas valide</div>
              ) : (
                ""
              )}
              <div className="field is-grouped" style={{ marginTop: "2rem" }}>
                <div className="control">
                  <Link
                    to="/apps/mon-compte/init-mdp/"
                    style={{
                      fontSize: "0.9rem",
                      position: "relative",
                      top: "0.3rem",
                    }}
                  >
                    Mot de passe oublié&nbsp;?
                  </Link>
                </div>
                <div className="control">
                  <button type="submit" className={`button is-primary ${loading.loading ? "is-loading" : ""}`} data-cy="connexion">
                    <FontAwesomeIcon className="mr-1" icon={faSignInAlt} />
                    Connexion
                  </button>
                </div>
              </div>
              <hr />
              <Link
                to="/inscription/"
                style={{
                  fontSize: "0.9rem",
                  position: "relative",
                  top: "0.3rem",
                }}
              >
                <div className="button is-primary">Créez votre compte</div>
              </Link>
            </form>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default ConnexionPage
