import { login, clearLoading, showLoading } from "./../actions"
import { removeAllProducts } from "./panier"
import { sendMessage } from "../services/slack"

export const auth = (username, password) => {
  var url = process.env.GATSBY_API_URL + "/index.php?controller=authentification"
  return (dispatch) => {
    dispatch(showLoading())
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        action: "connect",
        email: username,
        password: password,
        site: process.env.SITE,
      }),
    })
      .then(function (response) {
        return response.json()
      })
      .then(function (result) {
        if (result.status == "error") {
          dispatch(clearLoading(false, ""))
        } else {
          dispatch(clearLoading(true, ""))
          dispatch(login(result))
        }
      })
      .catch(function (error) {
        dispatch(clearLoading(false, ""))
        sendMessage("erreur appel API ", [
          {
            type: "section",
            text: {
              type: "mrkdwn",
              text: "```" + error + "```",
            },
          },
        ])
        console.log("Request failed", error)
      })
  }
}

export const inscription = (infos) => {
  var url = process.env.GATSBY_API_URL + "/index.php?controller=authentification"
  return (dispatch) => {
    dispatch(showLoading())
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        action: "inscription",
        infos: infos,
        site: process.env.SITE,
      }),
    })
      .then((response) => {
        return response.json()
      })
      .then((result) => {
        if (result.status == "error") {
          dispatch(clearLoading(false))
        } else {
          dispatch(clearLoading(true))
          dispatch(login(result))
        }
      })
      .catch(function (error) {
        console.log("Request failed", error)
      })
  }
}

export const deleteAddress = (id_address) => {
  var url = process.env.GATSBY_API_URL + "/index.php?controller=authentification"
  return (dispatch, getState) => {
    dispatch(showLoading())
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        action: "deleteAddress",
        id_address: id_address,
        email: getState().identifiants.identifiants.email,
        hash: getState().identifiants.identifiants.hash,
        site: process.env.SITE,
      }),
    })
      .then(function (response) {
        dispatch(clearLoading())
        return response.json()
      })
      .then(function (result) {
        dispatch(clearLoading(true))
        dispatch(login(result))
      })
      .catch(function (error) {
        console.log("Request failed", error)
      })
  }
}

export const updateInfos = (data, id_order = null) => {
  var url = process.env.GATSBY_API_URL + "/index.php?controller=authentification"

  return (dispatch, getState) => {
    dispatch(showLoading())
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        action: "updateInfos",
        email: getState().identifiants.identifiants.email,
        hash: getState().identifiants.identifiants.hash,
        data: data,
        site: process.env.SITE,
      }),
    })
      .then(function (response) {
        return response.json()
      })
      .then(function (result) {
        if (id_order && result.orders.find((elem) => elem.id_order === id_order)) {
          dispatch(removeAllProducts({}))
        }
        if (result.status === "error") {
          dispatch(clearLoading(false, result.message))
        } else {
          setTimeout(
            function () {
              dispatch(clearLoading(true))
              dispatch(login(result))
            }.bind(this),
            500
          )
        }
      })
      .catch(function (error) {
        clearLoading(false)
        console.log("Request failed", error)
      })
  }
}

export const updateMDP = (id_customer, data) => {
  var url = process.env.GATSBY_API_URL + "/index.php?controller=authentification"
  return (dispatch) => {
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        action: "updateMDP",
        id_customer: id_customer,
        data: data,
        site: process.env.SITE,
      }),
    })
      .then(function (response) {
        return response.json()
      })
      .then(function (result) {
        clearLoading(true)
        dispatch(login(result))
      })
      .catch(function (error) {
        console.log("Request failed", error)
      })
  }
}
